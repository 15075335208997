const CoachesPage = () => import(/* webpackPrefetch: true */ '@/pages/CoachesPage.vue');

export default [
  {
    path: '/coaches',
    name: 'CoachesPage',
    component: CoachesPage,
    meta: {
      layout: 'Page',
      title: 'Coaches',
      pageTitle: 'UNIT 3: Coaches',
      seo: {
        title: 'UNIT 3: Health & Fitness | Coaches',
        description: 'Meet the coaches at UNIT 3'
      }
    }
  }
];
