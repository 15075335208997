<template>
  <div
    class="flex flex-col w-full text-left h-auto"
    :class="[bordered && 'border-primary border-t-4']"
  >
    <div
      :class="['h-full bg-no-repeat bg-cover bg-center', background]"
      :style="backgroundImage && `background-image: url(${backgroundImage});`"
    >
      <div class="h-full w-full bg-black bg-opacity-85 flex flex-col p-6 mb-8">
        <div class="text-left">
          <slot name="subtitle">
            <Subtitle :subtitle="subtitle" />
          </slot>
          <slot name="title">
            <Title :title="title" />
          </slot>
          <slot name="profile" />
          <slot name="media" />
          <slot name="body">
            <Body :body="body" />
          </slot>
        </div>
        <div class="mt-auto">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Title, Subtitle,  Body } from './index';

export default {
  name: 'Card',
  components: {
    Title,
    Subtitle,
    Body
  },
  props: {
    title: {
      type: String,
      default: 'title'
    },
    subtitle: {
      type: String,
      default: 'subtitle'
    },
    body: {
      type: String,
      default: 'body'
    },
    background: {
      type: String,
      default: 'bg-black'
    },
    backgroundImage: {
      type: String,
      default: null
    },
    bordered: {
      type: Boolean,
      default: false
    }
  }
};
</script>
