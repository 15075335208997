import { SET_TESTIMONIALS, SET_TESTIMONIALS_LOADING, SET_TESTIMONIALS_ERROR } from './types';

export default {
  [SET_TESTIMONIALS](state, testimonials) {
    state.testimonials = testimonials;
  },
  [SET_TESTIMONIALS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_TESTIMONIALS_ERROR](state, { error, isError }) {
    state.error = {
      message: error,
      isError
    };
  }
};
