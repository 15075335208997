<template>
  <p
    class="text-primary font-base leading-normal text-left uppercase"
    :class="[
      'text-xs',
    ]"
  >
    {{ subtitle }}
  </p>
</template>

<script>
export default {
  name: 'Subtitle',
  props: {
    subtitle: {
      type: String,
      default: ''
    }}
};
</script>
