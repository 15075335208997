<template>
  <img
    v-bind="$attrs"
    class="overflow-hidden rounded-full"
    :src="source"
    :alt="text"
  >
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    source: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
