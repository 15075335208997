import LandingPage from '@/pages/LandingPage.vue';

export default [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      layout: 'Default',
      pageTitle: 'UNIT 3. A different kind of gym...',
      linkContent: 'to learn more',
      seo: {
        title: 'UNIT 3: Health & Fitness | Home',
        og_title: 'UNIT 3: Health & Fitness | Home',
        og_site_name: 'UNIT 3: Health & Fitness',
        og_type: 'website',
        description: 'Much more than your typical gym. Our goal is to create a strong healthy community of strong, healthy people. UNIT 3: Health and Fitness was built by three friends who share the same values and philosophies on well-being and who believe that improving your health can lead to noticeable improvements in all the other areas of your life.'
      }
    }
  }
];
