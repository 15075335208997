import axios from 'axios';

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const axiosConfig = {
  header: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

export default {
  showAlert: async (context, { alertState, message, show }) => {
    context.commit('setShowAlert', { alertState, message, show });
    setTimeout(
      () => context.commit('setShowAlert', { alertState: null, message: null, show: false }),
      4000
    );
  },
  toggleModal: async (context, { modalName, show, subject }) => {
    context.commit('toggleModal', { modalName, show, subject });
  },
  submitBasicContactForm: async (context, { formName, form, message }) => {
    axios
      .post(
        '/',
        encode({
          'form-name': formName,
          ...form,
          subject: form.subject
        }),
        axiosConfig
      )
      .then(() =>
        context.dispatch('showAlert', {
          message,
          alertState: 'success',
          show: true
        })
      )
      .catch(() =>
        context.dispatch('showAlert', {
          message: 'Something went wrong',
          alertState: 'error',
          show: true
        })
      );
  }
};
