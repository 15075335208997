const AthletesPage = () => import(/* webpackPrefetch: true */ '@/pages/AthletesPage.vue');

export default [
  {
    path: '/athletes',
    name: 'AthletesPage',
    component: AthletesPage,
    meta: {
      layout: 'Page',
      title: 'High Performance Athletic Coaching',
      pageTitle: 'UNIT 3: High performance',
      linkContent: 'about high performance athletic coaching',
      seo: {
        title: 'UNIT 3: Health & Fitness | Athletes',
        description: 'High Performance Athletic Coaching at UNIT 3'
      }
    }
  }
];
