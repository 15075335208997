const QUERY_TESTIMONIALS = (page = 'athletes') => `
    *[_type == "testimonial" && page == '${page}']  | order(order asc) {
        achievements,
        athlete,
        comment,
        "image": profileImage{
            asset->{
                _id,
                url
            }
        },
        sport
    }
`;

export default QUERY_TESTIMONIALS;
