<template>
  <div class="flex flex-row gap-x-4 w-auto text-white py-4 items-center">
    <Avatar
      :source="image"
      :text="author"
      class="w-12 h-12"
    />
    <div class="flex flex-col justify-center">
      <div class="font-thin text-xs leading-6 text-secondary-200">
        Author
      </div>
      <div class="font-thin text-primary text-base leading-6">
        {{ author }}
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar/Avatar.vue';

export default {
  name: 'Author',
  components: {
    Avatar
  },
  props: {
    image: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
