const TimetablePage = () => import(/* webpackPrefetch: true */ '@/pages/TimetablePage.vue');

export default [
  {
    path: '/timetable',
    name: 'TimetablePage',
    component: TimetablePage,
    meta: {
      layout: 'Page',
      title: 'Timetable',
      linkContent: 'about high performance athletic coaching',
      seo: {
        title: 'UNIT 3: Health & Fitness | Timetable',
        description: 'Book in to a class at UNIT 3'
      }
    }
  }
];
