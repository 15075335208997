const QUERY_IMAGES = (page = 'teens') => `
    *[_type == "images" && page == '${page}'] {
        "image": image{
            asset->{
                _id,
                url
            }
        }
    }
`;

export default QUERY_IMAGES;
