const GlofoxPage = () => import(/* webpackPrefetch: true */ '@/pages/GlofoxPage.vue');

export default [
  {
    path: '/glofox/class-booking',
    name: 'GlofoxPage',
    component: GlofoxPage,
    meta: {
      layout: 'Page',
      title: 'Book a class',
      pageTitle: 'UNIT 3: Book a class',
      seo: {
        title: 'UNIT 3: Book a class',
        description: 'Book into one of our classes through Glofox'
      }
    }
  }
];
