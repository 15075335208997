<template>
  <PageSection
    :title="'Download our new app'"
    class="bg-secondary-100"
  >
    <SectionText>
      We've changed our booking system for our classes at UNIT 3!
    </SectionText>
    <SectionText>
      To book into a class, you'll need to use our new app, which can be downloaded below
    </SectionText>
    <div class="flex mt-3  text-white rounded-xl items-center justify-center text-left gap-12">
      <a
        role="link"
        href="https://apps.apple.com/us/app/unit-3-health-and-fitness/id644591574"
        rel="noreferrer"
        target="_blank"
        aria-label="Download on the app store"
      >
        <AppStore />
      </a>

      <a
        role="link"
        href="https://play.google.com/store/apps/details?id=ie.zappy.fennec.unithealthandfitness&pli=1"
        rel="noreferrer"
        target="_blank"
        aria-label="Get it on Google play"
      >
        <GooglePlay />
      </a>
    </div>
  </PageSection>
</template>

<script>
import AppStore from '../shared/Buttons/AppStore.vue';
import GooglePlay from '../shared/Buttons/GooglePlay.vue';
import { PageSection, SectionText } from '@/pages/shared/index';


export default {
  name: 'BookingInfo',
  components: {
    PageSection,
    SectionText,
    AppStore,
    GooglePlay
  },
  props: {
    classes: {
      type: Array,
      default: () => []
    }
  }

};
</script>
