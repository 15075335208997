export default {
  landingPageCards: (state) => state.pages.landing.cards.training,
  coaches: (state) => state.coaches,
  alert: (state) => state.alert,
  beginnerClasses: (state) =>
    state.classes.filter((group) =>
      [ 'beginners', 'adults', 'pt' ].some((i) => group.tags.includes(i))
    ),
  athleteClasses: (state) =>
    state.classes.filter((group) => [ 'athletes', 'pt' ].some((i) => group.tags.includes(i))),
  kidsClasses: (state) =>
    state.classes.filter((group) => [ 'kids', 'teens' ].some((i) => group.tags.includes(i))),
  contactModal: (state) => state.modals.contact.show,
  locationModal: (state) => state.modals.location.show,
  isModalOpen: (state) => state.modals.open,
  formSubject: (state) => state.modals.subject
};
