import sanity from '@/infrastructure/sanity/sanity';
import QUERY_COACHES from './queries';
import { SET_COACHES, SET_COACHES_LOADING, SET_COACHES_ERROR } from './types';

export default {
  fetchCoaches: async ({ commit, state }) => {
    commit(SET_COACHES_LOADING, true);
    commit(SET_COACHES_ERROR, { description: null, isError: false, code: null });

    if (!state.coaches.length > 0) {
      sanity.fetch(QUERY_COACHES).then(
        (coaches) => {
          commit(SET_COACHES, coaches);
        },
        (error) => {
          commit(SET_COACHES_ERROR, {
            description: error.details.description,
            code: error.response.statusCode,
            isError: true,
            query: 'fetchClasses'
          });
        }
      );
    }
    commit(SET_COACHES_LOADING, false);
  }
};
