export default {
  setShowAlert(state, { alertState, message, show }) {
    state.alert.show = show;
    state.alert.alertState = alertState;
    state.alert.message = message;
  },
  toggleModal(state, { modalName, show, subject }) {
    state.modals[modalName].show = show;
    state.modals.open = show;
    if (show) this.state.modals.subject = subject;
    else {
      this.state.modals.subject = 'General enquiry from unit3.ie';
    }
  }
};
