<template>
  <div class="flex flex-col w-full">
    <slot name="content">
      <p
        class="white-text font-thin leading-normal py-12 capitalize"
        :class="[
          'text-2xl ',
          'text-center ',
          'lg:text-4xl'
        ]"
      >
        {{ content }}
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
};
</script>
