const status = {
  isLoading: false,
  isError: false,
  error: {
    description: null,
    code: null,
    query: null
  }
};

export default status;
