<template>
  <div class="h-full w-screen">
    <Banner @scroll-to="handleScrollTo('#ex-welcome', -60)" />
    <Welcome id="ex-welcome" />
    <TargetSection :card-data="landingPageCards" />
    <BookingInfo id="booking-info" />
    <Classes @scroll-to="handleScrollTo('#booking-info', -60)" />
    <Campaign
      @click-campaign="
        (title) =>
          toggleModal({
            modalName: 'contact',
            subject: title,
            show: true,
          })
      "
    />
    <Coaches :can-meet-coaches="features.meet_coaches" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Welcome, Coaches, BookingInfo } from '@/pages/LandingPage/index';
import { Banner } from '@/pages/shared/index';
import lazyLoadComponent from '@/plugins/lazyLoadComponent.js';
import Skeleton from '../components/atoms/Frames/Loading/Skeleton.vue';

const VueScrollTo = require('vue-scrollto');

const defaultOptions = {
  loading: Skeleton,
  loadingData: {
    props: {
      width: '100%',
      height: '20em'
    }
  }
};

export default {
  name: 'LandingPage',
  components: {
    Banner,
    Welcome,
    BookingInfo,
    TargetSection: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import('@/pages/LandingPage/TargetSection.vue')
    }),
    Classes: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import('@/pages/LandingPage/Classes.vue')
    }),
    // Coaches,
    Campaign: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import('@/pages/LandingPage/Campaign.vue')
    }),
    Coaches
  },
  props: {
    features: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([ 'landingPageCards', 'coaches' ])
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    handleScrollTo(id, offset) {
      VueScrollTo.scrollTo(id, 500, { force: true, offset });
    }
  }
};
</script>
