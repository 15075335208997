import status from '../status';

const initialState = () => ({
  mainCampaign: null,
  isLoading: false,
  error: {
    message: null,
    isError: false
  },
  status
});

export default initialState;
