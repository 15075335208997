import sanity from '@/infrastructure/sanity/sanity';
import QUERY_FEATURES from './queries';
import { SET_FEATURES, SET_FEATURES_LOADING, SET_FEATURES_ERROR } from './types';

export default {
  fetchFeatures: async ({ commit }) => {
    commit(SET_FEATURES_LOADING, true);
    commit(SET_FEATURES_ERROR, { description: null, isError: false, code: null });
    sanity.fetch(QUERY_FEATURES).then(
      (features) => {
        commit(SET_FEATURES, features);
        return features;
      },
      (error) => {
        commit(SET_FEATURES_ERROR, {
          description: error.details.description,
          code: error.response.statusCode,
          isError: true,
          query: 'fetchClasses'
        });
      }
    );
    commit(SET_FEATURES_LOADING, false);
  }
};
