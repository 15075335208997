<template>
  <div
    class="alert"
    :class="state"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    state: {
      type: String,
      required: true,
      validator: (value) => [ 'success', 'error' ].includes(value)
    },
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.alert {
  @apply fixed top-0 left-0 right-0 z-50 mt-6 w-1/2 mx-auto p-4 rounded;
  &.success {
    @apply bg-primary text-black;
  }
  &.error {
    @apply bg-red-400;
  }
}
</style>
