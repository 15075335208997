const BlogIndex = () => import(/* webpackPrefetch: true */ '@/pages/BlogIndex.vue');
const BlogPost = () => import(/* webpackPrefetch: true */ '@/pages/BlogPost.vue');
export default [
  {
    path: '/blog',
    name: 'BlogIndex',
    component: BlogIndex,
    meta: {
      layout: 'Page',
      title: 'Blog',
      pageTitle: 'UNIT 3. A different kind of gym...',
      linkContent: 'to learn more',
      seo: {
        title: 'UNIT 3: Health & Fitness | Blog',
        description: 'UNIT 3 blog'
      }
    }
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost,
    meta: {
      layout: 'BlogPost',
      title: 'Blog',
      pageTitle: 'UNIT 3. A different kind of gym...',
      linkContent: 'to learn more',
      seo: {
        title: 'UNIT 3: Health & Fitness | Blog',
        description: 'UNIT 3 blog'
      }
    }
  }
];
