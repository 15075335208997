import {
  SET_CLASSES,
  SET_CLASSES_LOADING,
  SET_CLASSES_ERROR,
  SET_CLASSES_FETCH_TIME
} from './types';

export default {
  [SET_CLASSES](state, classes) {
    state.classes = classes;
  },
  [SET_CLASSES_LOADING](state, isLoading) {
    state.status.isLoading = isLoading;
  },
  [SET_CLASSES_ERROR](state, { description, code, isError, query }) {
    state.status.isError = isError;
    state.status.error.code = code;
    state.status.error.query = query;
    state.status.error.description = description;
  },
  [SET_CLASSES_FETCH_TIME](state, time) {
    state.lastFetchTime = time;
  }
};
