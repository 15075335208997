import sanity from '@/infrastructure/sanity/sanity';
import QUERY_GYM_CLASSES from './queries';
import {
  SET_CLASSES,
  SET_CLASSES_LOADING,
  SET_CLASSES_ERROR,
  SET_CLASSES_FETCH_TIME
} from './types';

import { shouldFetchAgain } from '../../utils';

export default {
  fetchClasses: async ({ commit, state }) => {
    commit(SET_CLASSES_LOADING, true);
    commit(SET_CLASSES_ERROR, { description: null, isError: false, code: null });

    const lastFetchedTime = state?.lastFetchTime || null;
    const now = new Date().getTime();

    if (!state.classes.length > 0 || shouldFetchAgain(now, lastFetchedTime)) {
      commit(SET_CLASSES_FETCH_TIME, now);
      sanity.fetch(QUERY_GYM_CLASSES).then(
        (classes) => {
          commit(SET_CLASSES, classes);
        },
        (error) => {
          commit(SET_CLASSES_ERROR, {
            description: error.details.description,
            code: error.response.statusCode,
            isError: true,
            query: 'fetchClasses'
          });
        }
      );
    }
    commit(SET_CLASSES_LOADING, false);
  }
};
