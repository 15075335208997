<template>
  <Drawer v-show="isOpen">
    <div class="flex flex-col relative h-full w-full">
      <Container class="w-full flex flex-row justify-end py-8 absolute">
        <button
          :name="'toggle-menu'"
          class="outline-none focus:outline-none"
          @click="$emit('clicked')"
        >
          <font-awesome-icon
            icon="fa-solid fa-times"
            size="lg"
            class="white-text"
          />
        </button>
      </Container>
      <Container class="w-full h-full flex flex-col justify-center items-center py-8">
        <Logo :class="'h-12 mb-12'" />
        <template v-for="(item, index) in items.filter(i => !i.subMenu && !i.extra)">
          <router-link
            v-if="features[item.flag] || item.flag"
            :key="index"
            :to="item.to"
          >
            <button
              class="text-white text-2xl border-transparent hover:border-primary border-b-4 opacity-70 font-thin  hover:opacity-100"
              :class="$route.name === item.active && 'font-bold opacity-100'"
              @click="$emit('selected')"
            >
              {{ item.name }}
            </button>
          </router-link>
        </template>
        <div class="flex flex-row gap-x-4 mt-24">
          <a
            role="link"
            href="https://www.facebook.com/UNIT3HealthandFitness/"
            rel="noreferrer"
            target="_blank"
            aria-label="UNIT 3 on Facebook"
          >
            <font-awesome-icon
              icon="fa-brands fa-facebook"
              size="2x"
              class="opacity-80 hover:text-primary cursor-pointer"
            />
          </a>
          <a
            role="link"
            href="https://www.instagram.com/unit3healthandfitness/"
            rel="noreferrer"
            target="_blank"
            aria-label="UNIT 3 on Instrgram"
          >
            <font-awesome-icon
              icon="fa-brands fa-instagram"
              size="2x"
              class="opacity-80 hover:text-primary cursor-pointer"
            />
          </a>
          <a
            role="link"
            href="https://www.youtube.com/channel/UCYXo_XUVxExFz0jmgboemFw"
            rel="noreferrer"
            target="_blank"
            aria-label="UNIT 3 on Youtube"
          >
            <font-awesome-icon
              icon="fa-brands fa-youtube"
              size="2x"
              class="opacity-80 hover:text-primary cursor-pointer"
            />
          </a>
        </div>
      </Container>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/atoms/Navigation/Drawer.vue';
import Container from '@/components/atoms/Frames/Container.vue';
import NavItems from './nav.items';
import Logo from '@/components/atoms/Brand/Logo.vue';

export default {
  name: 'NavigationDrawer',
  components: {
    Drawer,
    Logo,
    Container
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    features: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    items: NavItems
  })
};
</script>
