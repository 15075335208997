const QUERY_MAIN_CAMPAIGN = `
    *[_type == "campaign" && isPrimary == true]{
        title,
        emailSubject,
        body,
        "image": image{
            asset->{
                _id,
                url
            }
        }
    }[0]
`;

export default QUERY_MAIN_CAMPAIGN;
