import { SET_FEATURES, SET_FEATURES_LOADING, SET_FEATURES_ERROR } from './types';

export default {
  [SET_FEATURES](state, features) {
    state.features = features;
  },
  [SET_FEATURES_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_FEATURES_ERROR](state, { error, isError }) {
    state.error = {
      message: error,
      isError
    };
  }
};
