<template>
  <div class="wysiwyg">
    <BlockContent
      :blocks="content"
      :serializers="serializers"
    />
  </div>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component';
import HighlightMark from './HighlightMark';

export default {
  name: 'BlogPost',
  components: { BlockContent },
  props: {
    content: {
      type: Array,
      default: () => []
    }
  },
  data:() => ({
    serializers: {
      types: {
      },
      marks: {
        highlight: HighlightMark
      }
    }
  })};
</script>
<style scoped lang="scss">
.wysiwyg {
  line-height: 1.15;
  blockquote {
    font-style: italic;
  }
  p, blockquote {
    margin: 0;
    @apply font-thin opacity-70 leading-tight my-2 text-center text-sm mb-6;
    strong {
      @apply font-semibold text-primary;
    }
  }
  ul, li, ol {
    @apply font-thin opacity-70 leading-tight my-2 text-left text-sm;
  }
  ul {
    list-style-position: outside;
    margin-left: 1.4em;
  }
  ol {
    list-style-type: decimal;
  }
   ol ol {
    list-style-type: lower-alpha;
  }
   ol ol ol {
    list-style-type: lower-roman;
  }
   ol ol ol ol {
    list-style-type: lower-greek;
  }
   ol ol ol ol ol {
    list-style-type: decimal;
  }
   ol ol ol ol ol ol {
    list-style-type: lower-alpha;
  }
   ul {
    list-style-type: disc;
  }
   ul ul {
    list-style-type: circle;
  }
   ul ul ul {
    list-style-type: square;
  }
   ul ul ul ul {
    list-style-type: circle;
  }
   ul ul ul ul ul {
    list-style-type: disc;
  }
   ul ul ul ul ul ul {
    list-style-type: circle;
  }
  h1 {@apply text-4xl leading-tight mb-4}
  h2 {@apply text-3xl leading-tight mb-4}
  h3 {@apply text-xl leading-tight mb-4}
  h4 {@apply text-xl leading-tight mb-4}
  h5 {@apply text-lg leading-tight mb-4}
  h6 {@apply text-base leading-tight mb-4}
  @screen md {
    p, blockquote { @apply text-sm text-left; }
    h1 {@apply text-4xl leading-tight mb-4}
    h2 {@apply text-3xl leading-tight mb-4}
    h3 {@apply text-2xl leading-tight mb-4}
    h4 {@apply text-xl leading-tight mb-4}
    h5 {@apply text-lg leading-tight mb-4}
    h6 {@apply text-base leading-tight mb-4}
    ul, li, ol {
      @apply text-sm;
    }
  }
  @screen lg {
    p, blockquote { @apply text-sm text-left; }
    h1 {@apply text-5xl leading-tight mb-4}
    h2 {@apply text-4xl leading-tight mb-4}
    h3 {@apply text-3xl leading-tight mb-4}
    h4 {@apply text-2xl leading-tight mb-4}
    h5 {@apply text-2xl leading-tight mb-4}
    h6 {@apply text-2xl leading-tight mb-4}
    ul, li, ol {
      @apply text-sm;
    }
  }
  @screen xl {
    p, blockquote{ @apply text-base text-left; }
    h1 {@apply text-5xl leading-tight mb-4}
    h2 {@apply text-4xl leading-tight mb-4}
    h3 {@apply text-3xl leading-tight mb-4}
    h4 {@apply text-3xl leading-tight mb-4}
    h5 {@apply text-3xl leading-tight mb-4}
    h6 {@apply text-3xl leading-tight mb-4}
    ul, li, ol {
      @apply text-base;
    }
  }
  
}

// .wysiwyg abbr {
//   border-bottom: none; /* 1 */
//   text-decoration: underline; /* 2 */
//   -webkit-text-decoration: underline dotted;
//           text-decoration: underline dotted; /* 2 */
// }
// .wysiwyg cite {
//   font-style: italic;
// }
// .wysiwyg hr {
//   margin: 0;
//   border-top-width: 1px;
//   background: #e6e6e6;
//   border: none;
//   display: block;
//   height: 1px;
//   margin-bottom: 1.4em;
//   margin-top: 1.4em;
// }
// .wysiwyg img {
//   vertical-align: text-bottom;
// }
// .wysiwyg ins {
//   background-color: lime;
//   text-decoration: none;
// }
// .wysiwyg mark {
//   background-color: #ff0;
// }
// .wysiwyg small {
//   font-size: 0.8em;
// }
// .wysiwyg sub,
// .wysiwyg sup {
//   font-size: 0.8em;
// }
// .wysiwyg sub {
//   vertical-align: sub;
// }
// .wysiwyg sup {
//   vertical-align: super;
// }
// .wysiwyg dl,
// .wysiwyg ol,
// .wysiwyg ul,
// .wysiwyg blockquote,
// .wysiwyg pre,
// .wysiwyg table {
//   margin-bottom: 1.4em;
// }
// .wysiwyg dl:last-child,
// .wysiwyg ol:last-child,
// .wysiwyg ul:last-child,
// .wysiwyg blockquote:last-child,
// .wysiwyg pre:last-child,
// .wysiwyg table:last-child {
//   margin-bottom: 0;
// }
// .wysiwyg p:empty {
//   display: none;
// }
// .wysiwyg h1,
// .wysiwyg h2,
// .wysiwyg h4,
// .wysiwyg h5,
// .wysiwyg h6 {
//   font-weight: 700;
//   line-height: 1.2;
// }
// .wysiwyg h1:first-child,
// .wysiwyg h2:first-child,
// .wysiwyg h4:first-child,
// .wysiwyg h5:first-child,
// .wysiwyg h6:first-child {
//   margin-top: 0;
// }
// .wysiwyg h1 {
//   font-size: 2.4em;
//   margin-bottom: 0.58333em;
//   margin-top: 0.58333em;
//   line-height: 1;
// }
// .wysiwyg h2 {
//   font-size: 1.6em;
//   margin-bottom: 0.875em;
//   margin-top: 1.75em;
//   line-height: 1.1;
// }

// .wysiwyg h4 {
//   font-size: 1.2em;
//   margin-bottom: 1.16667em;
//   margin-top: 1.16667em;
// }
// .wysiwyg h5 {
//   font-size: 1.1em;
//   margin-bottom: 1.27273em;
//   margin-top: 1.27273em;
// }
// .wysiwyg h6 {
//   font-size: 1em;
//   margin-bottom: 1.4em;
//   margin-top: 1.4em;
// }
// .wysiwyg dd {
//   margin-left: 1.4em;
// }
// .wysiwyg ol,
// .wysiwyg ul {
//   list-style-position: outside;
//   margin-left: 1.4em;
// }
// .wysiwyg ol {
//   list-style-type: decimal;
// }
// .wysiwyg ol ol {
//   list-style-type: lower-alpha;
// }
// .wysiwyg ol ol ol {
//   list-style-type: lower-roman;
// }
// .wysiwyg ol ol ol ol {
//   list-style-type: lower-greek;
// }
// .wysiwyg ol ol ol ol ol {
//   list-style-type: decimal;
// }
// .wysiwyg ol ol ol ol ol ol {
//   list-style-type: lower-alpha;
// }
// .wysiwyg ul {
//   list-style-type: disc;
// }
// .wysiwyg ul ul {
//   list-style-type: circle;
// }
// .wysiwyg ul ul ul {
//   list-style-type: square;
// }
// .wysiwyg ul ul ul ul {
//   list-style-type: circle;
// }
// .wysiwyg ul ul ul ul ul {
//   list-style-type: disc;
// }
// .wysiwyg ul ul ul ul ul ul {
//   list-style-type: circle;
// }
// .wysiwyg blockquote {
//   padding: 0 0 0 0.6rem;
//   font-style: italic;
//   font-weight: 200;
// }
// .wysiwyg blockquote p {
//   margin-bottom: 0;
// }
// .wysiwyg code,
// .wysiwyg kbd,
// .wysiwyg samp,
// .wysiwyg pre {
//   -moz-osx-font-smoothing: auto;
//   -webkit-font-smoothing: auto;
//   background-color: #f2f2f2;
//   color: #333;
//   font-size: 0.9em;
// }
// .wysiwyg code,
// .wysiwyg kbd,
// .wysiwyg samp {
//   border-radius: 3px;
//   line-height: 1.77778;
//   padding: 0.1em 0.4em 0.2em;
//   vertical-align: baseline;
// }
// .wysiwyg pre {
//   overflow: auto;
//   padding: 1em 1.2em;
// }
// .wysiwyg pre code {
//   background: none;
//   font-size: 1em;
//   line-height: 1em;
// }
// .wysiwyg figure {
//   margin-bottom: 2.8em;
//   text-align: center;
// }
// .wysiwyg figure:first-child {
//   margin-top: 0;
// }
// .wysiwyg figure:last-child {
//   margin-bottom: 0;
// }
// .wysiwyg figcaption {
//   font-size: 0.8em;
//   margin-top: 0.875em;
// }
// .wysiwyg table {
//   width: 100%;
// }
// .wysiwyg table pre {
//   white-space: pre-wrap;
// }
// .wysiwyg th,
// .wysiwyg td {
//   font-size: 1em;
//   padding: 0.7em;
//   border: 1px solid #e6e6e6;
//   line-height: 1.4;
// }
// .wysiwyg thead tr,
// .wysiwyg tfoot tr {
//   background-color: #f5f5f5;
// }
// .wysiwyg thead th,
// .wysiwyg thead td,
// .wysiwyg tfoot th,
// .wysiwyg tfoot td {
//   font-size: 0.9em;
//   padding: 0.77778em;
// }
// .wysiwyg thead th code,
// .wysiwyg thead td code,
// .wysiwyg tfoot th code,
// .wysiwyg tfoot td code {
//   background-color: #fff;
// }
// .wysiwyg tbody tr {
//   background-color: #fff;
// }
</style>
