import sanity from '@/infrastructure/sanity/sanity';
import QUERY_MAIN_CAMPAIGN from './queries';
import { SET_MAIN_CAMPAIGN_LOADING, SET_MAIN_CAMPAIGN, SET_MAIN_CAMPAIGN_ERROR } from './types';

export default {
  fetchMainCampaign: async ({ commit }) => {
    commit(SET_MAIN_CAMPAIGN_LOADING, true);
    commit(SET_MAIN_CAMPAIGN_ERROR, { error: null, isError: false });

    sanity.fetch(QUERY_MAIN_CAMPAIGN).then(
      (campaign) => {
        commit(SET_MAIN_CAMPAIGN, campaign);
      },
      (error) => {
        commit(SET_MAIN_CAMPAIGN_ERROR, { error, isError: true });
      }
    );
    commit(SET_MAIN_CAMPAIGN_LOADING, false);
  }
};
