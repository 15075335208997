const ReturningPage = () => import(/* webpackPrefetch: true */ '@/pages/ReturningPage.vue');

export default [
  {
    path: '/returning',
    name: 'ReturningPage',
    component: ReturningPage,
    meta: {
      layout: 'Page',
      title: 'Let us help you reach your goals.',
      pageTitle: 'UNIT 3: Return to training',
      linkContent: 'about returning to training with UNIT 3',
      seo: {
        title: 'UNIT 3: Health & Fitness | Return to training',
        description: 'Everyone starts somewhere. Start with UNIT 3.'
      }
    }
  }
];
