<template>
  <div
    class="bg-secondary-DEFAULT w-screen h-60-screen flex flex-col items-center justify-center p-12 relative"
  >
    <span class="bg-secondary-200 animate-pulse rounded-xl h-full w-full" />
  </div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    maxWidth: {
      // The default maxiumum width is 100%.
      default: 100,
      type: Number
    },
    minWidth: {
      // Lines have a minimum width of 80%.
      default: 80,
      type: Number
    },
    height: {
      // Make lines the same height as text.
      default: '100%',
      type: String
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String
    }
  },
  computed: {
    computedWidth() {
      return (
        this.width ||
        `${Math.floor(Math.random() * (this.maxWidth - this.minWidth) + this.minWidth)}%`
      );
    }
  }
};
</script>
