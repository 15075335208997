<template>
  <PageSection class="bg-transparent">
    <div class="h-30-screen w-screen text-white flex flex-col gap-2 items-center justify-center">
      <font-awesome-icon
        icon="fa-solid fa-spinner"
        size="2xl"
        class="text-primary "
        spin-pulse
      />
    </div>
  </PageSection>
</template>

<script>
import PageSection from './PageSection.vue';

export default {
  name: 'LoadingState',
  components: {
    PageSection
  }
};
</script>
