import {
  SET_COACHES,
  SET_COACHES_LOADING,
  SET_COACHES_ERROR,
  SET_COACHES_FETCH_TIME
} from './types';

export default {
  [SET_COACHES](state, coaches) {
    state.coaches = coaches;
  },
  [SET_COACHES_LOADING](state, isLoading) {
    state.status.isLoading = isLoading;
  },
  [SET_COACHES_ERROR](state, { description, code, isError, query }) {
    state.status.isError = isError;
    state.status.error.code = code;
    state.status.error.query = query;
    state.status.error.description = description;
  },
  [SET_COACHES_FETCH_TIME](state, time) {
    state.lastFetchTime = time;
  }
};
