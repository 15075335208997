<template>
  <div
    :class="[
      'bg-black h-16',
      'fixed top-0 w-full flex justify-between items-center z-40 h-14'
    ]"
  >
    <Container class="justify-between">
      <div class="flex flex-row gap-x-4 items-center justify-center">
        <router-link to="/">
          <Logo class="h-8" />
        </router-link>
      </div>
      <button
        name="toggle-menu"
        class="outline-none focus:outline-none"
        @click="$emit('clicked')"
      >
        <font-awesome-icon
          icon="fa-solid fa-bars"
          size="lg"
          class="white-text"
        />
      </button>
    </Container>
  </div>
</template>

<script>

import Container from '@/components/atoms/Frames/Container.vue';
import Logo from '@/components/atoms/Brand/Logo.vue';

export default {
  name: 'StaticNavbar',
  components: {
    Container,
    Logo
  }
};
</script>

