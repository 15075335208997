import { SET_MAIN_CAMPAIGN_LOADING, SET_MAIN_CAMPAIGN, SET_MAIN_CAMPAIGN_ERROR } from './types';

export default {
  [SET_MAIN_CAMPAIGN](state, mainCampaign) {
    state.mainCampaign = mainCampaign;
  },
  [SET_MAIN_CAMPAIGN_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_MAIN_CAMPAIGN_ERROR](state, { error, isError }) {
    state.error = {
      message: error,
      isError
    };
  }
};
