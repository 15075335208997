<template>
  <div
    v-bind="$attrs"
    class="w-full h-auto flex flex-row"
    :class="[
      'px-6',
      'sm:px-6',
      'md:px-24',
      'lg:px-40'
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container'
};
</script>
