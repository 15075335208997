<template>
  <div
    id="app"
    class="overflow-x-hidden font-inter"
  >
    <transition name="fade">
      <div
        v-if="isModalOpen"
        class="overlay"
      />
    </transition>

    <Alert
      v-if="alert.show"
      :state="alert.alertState"
      :message="alert.message"
      :show="alert.show"
    />
    <ContactModal
      :is-open="contactModal"
      @close="
        toggleModal({
          modalName: 'contact',
          show: false,
        })
      "
    />
    <LocationModal 
      :is-open="locationModal"
      @close="
        toggleModal({
          modalName: 'location',
          show: false,
        })
      "
    />
    <component
      :is="layout"
      v-bind="$route.meta.layoutProps"
      class="pb-0"
      :features="getFeatures"
    >
      <router-view v-bind="{ features: getFeatures }" />
    </component>
  </div>
</template>

<script>

const DEFAULT_TITLE = 'UNIT 3: Health & Fitness';
const DEFAULT_DESCRIPTION = 'Much more than your typical gym';

import { mapGetters, mapActions } from 'vuex';

import Default from '@/layouts/Default.vue';
import Page from '@/layouts/Page.vue';
import BlogPost from '@/layouts/BlogPost.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import ContactModal from '@/components/organisms/Modals/ContactModal.vue';
import LocationModal from '@/components/organisms/Modals/LocationModal.vue';

export default {
  name: 'App',
  components: {
    Default,
    Page,
    BlogPost,
    Alert,
    ContactModal,
    LocationModal
  },
  metaInfo() {
    return {
      title: this.$route?.meta?.seo?.title || DEFAULT_TITLE,
      meta: [
        {name: 'description', content: this.$route?.meta?.seo?.description || DEFAULT_DESCRIPTION},
        {name: 'og:description', content: this.$route?.meta?.seo?.description || DEFAULT_DESCRIPTION},
        {name: 'og:title', content: this.$route?.meta?.seo?.og_title || DEFAULT_TITLE},
        {name: 'og:url', content: 'https://www.unit3.ie'},
        {name: 'og:site_name', content: 'UNIT 3: Health & Fitness'},
        {name: 'og:type', content: 'website'},
        {name: 'og:locale', content:'en_GB'},
        { name: 'robots', content: 'index,follow' }
      ]
    };
  },
  computed: {
    pageTitle() {
      return this.$route?.meta?.seo?.title || DEFAULT_TITLE;
    },
    pageDescription() {
      return this.$route?.meta?.seo?.description || DEFAULT_DESCRIPTION;
    },
    layout() {
      return this.$route.meta.layout || 'Default';
    },
    ...mapGetters([ 'alert', 'contactModal', 'isModalOpen', 'locationModal' ]),
    ...mapGetters('features', [
      'getFeatures',
      'getFeaturesError',
      'getFeaturesIsLoading',
      'getFeaturesIsError'
    ])
  },
  async created() {
    await this.fetchFeatures();
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('features', [ 'fetchFeatures' ])
  }
};
</script>
<style lang="scss">
.overlay {
  @apply fixed w-screen h-screen z-60 bg-black bg-opacity-70;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-enter-to {
  opacity: 0.5;
}
.fade-leave {
  opacity: 0.5;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
