<template>
  <Container
    v-bind="$attrs"
    :class="[
      'py-12',
      'h-full',
      'flex flex-col items-center justify-center text-center gap-y-4',
      reduced ? '' : 'min-h-3/4'
    ]"
  >
    <Logo
      v-if="withLogo"
      class="md:h-24 h-12"
    />
    <SectionTitle
      v-if="title"
      :content="title"
    />
    <slot />
  </Container>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import Container from '@/components/atoms/Frames/Container.vue';
import Logo from './Brand/Logo.vue';

export default {
  name: 'PageSection',
  components: {
    Container,
    SectionTitle,
    Logo
  },
  props: {
    title: {
      type: String,
      default: null
    },

    withLogo: {
      type: Boolean,
      default: false
    },
    reduced: {
      type: Boolean,
      default: false
    }
    
  }
};
</script>
