import { SET_IMAGES, SET_IMAGES_LOADING, SET_IMAGES_ERROR } from './types';

export default {
  [SET_IMAGES](state, images) {
    state.images = images;
  },
  [SET_IMAGES_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IMAGES_ERROR](state, { error, isError }) {
    state.error = {
      message: error,
      isError
    };
  }
};
