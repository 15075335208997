import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuex from 'vuex';
import router from '@/infrastructure/router';
import store from '@/infrastructure/store';
import VueObserveVisibility from 'vue-observe-visibility';
import 'intersection-observer';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faTimes, faAngleDown, faExclamationCircle, faSpinner, faMapLocation, faLocationPin } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Loading from '@/components/atoms/Frames/Loading/Loading.vue';
import LoadingError from '@/components/atoms/Frames/LoadingError/LoadingError.vue';

import App from './App.vue';

import '@/plugins/resize';
import '@/plugins/maps';

import '@/assets/styles/main.css';

library.add(faBars, faTimes, faAngleDown, faFacebook, faInstagram, faYoutube, faExclamationCircle, faSpinner, faMapLocation, faLocationPin);

Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(VueObserveVisibility);
Vue.component('Loading', Loading);
Vue.component('LoadingError', LoadingError);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
