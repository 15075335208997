var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iframe-container w-full bg-black"},[_c('div',{staticClass:"overflow-auto h-full w-full"},[_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"},{name:"resize",rawName:"v-resize",value:({
        log: false,
        checkOrigin: false,
        tolerance: 10,
        sizeHeight: true,
        heightCalculationMethod: 'lowestElement',
        minHeight: 600,
        bodyPadding: 0,
        scrolling: 'auto',
      }),expression:"{\n        log: false,\n        checkOrigin: false,\n        tolerance: 10,\n        sizeHeight: true,\n        heightCalculationMethod: 'lowestElement',\n        minHeight: 600,\n        bodyPadding: 0,\n        scrolling: 'auto',\n      }"}],ref:_vm.id,attrs:{"id":_vm.id,"src":_vm.source,"gesture":"media","allow":"encrypted-media","allowfullscreen":""},on:{"load":_vm.handleLoad}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],staticClass:"w-full text-gray-600 mb-12 text-right text-xs"},[_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.glofox.com"}},[_vm._v("powered by "),_c('b',[_vm._v("Glofox")])])}]

export { render, staticRenderFns }