import sanity from '@/infrastructure/sanity/sanity';
import QUERY_TESTIMONIALS from './queries';
import { SET_TESTIMONIALS, SET_TESTIMONIALS_LOADING, SET_TESTIMONIALS_ERROR } from './types';

export default {
  fetchTestimonials: async ({ commit }, page) => {
    commit(SET_TESTIMONIALS_LOADING, true);
    commit(SET_TESTIMONIALS_ERROR, { description: null, isError: false, code: null });
    sanity.fetch(QUERY_TESTIMONIALS(page)).then(
      (testimonials) => {
        commit(SET_TESTIMONIALS, testimonials);
      },
      (error) => {
        commit(SET_TESTIMONIALS_ERROR, {
          description: error.details.description,
          code: error.response.statusCode,
          isError: true,
          query: 'fetchTestimonials'
        });
      }
    );
    commit(SET_TESTIMONIALS_LOADING, false);
  }
};
