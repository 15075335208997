import Vue from 'vue';
import Vuex from 'vuex';

import initialState from './initialState';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

import gymClasses from './modules/gymClasses';
import coaches from './modules/coaches';
import mainCampaign from './modules/mainCampaign';
import testimonial from './modules/testimonials';
import features from './modules/features';
import images from './modules/images';

Vue.use(Vuex);

const createNewStore = () =>
  new Vuex.Store({
    state: initialState,
    actions,
    getters,
    mutations,
    modules: {
      gymClasses,
      coaches,
      mainCampaign,
      testimonial,
      features,
      images
    }
  });

const store = createNewStore();

export default store;
