<template>
  <Modal
    v-show="isOpen"
    class="bg-black"
  >
    <div class="flex flex-col relative h-full w-full">
      <Container class="w-full flex flex-row justify-end py-8">
        <button @click="$emit('close')">
          <font-awesome-icon
            icon="fa-solid fa-times"
            size="lg"
            class="white-text"
          />
        </button>
      </Container>
      <Container class="w-full flex flex-row justify-center my-12">
        <Logo :class="'h-20'" />
      </Container>
      <Container class="w-full h-auto flex flex-col justify-center items-center">
        <Basic @close="$emit('close')" />
      </Container>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import Container from '@/components/atoms/Frames/Container.vue';
import Basic from '@/components/organisms/Forms/Basic.vue';
import Logo from '@/components/atoms/Brand/Logo.vue';

export default {
  name: 'ContactModal',
  components: {
    Container,
    Modal,
    Basic,
    Logo
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
