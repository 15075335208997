<template>
  <PageSection class="bg-error">
    <div class="h-full w-full bg-error text-white flex flex-col gap-2 items-center justify-center">
      <font-awesome-icon
        icon="fa-solid fa-exclamation-circle"
        size="2xl"
        class="text-white "
      />
      <p class="text-red-200">
        Code: <b>{{ error.code }}</b>
      </p>
      <p class="text-red-200">
        Description: <b>{{ error.description }}</b>
      </p>
      <p class="text-red-200">
        Occured in: <b>{{ error.query }}</b>
      </p>
      <button
        class="bg-transparent border-2 border-red-200 font-semibold text-red-200 uppercase text-xs rounded-xl p-2 px-4 mt-12"
        @click="retry()"
      >
        Try again
      </button>
    </div>
  </PageSection>
</template>

<script>
import PageSection from './PageSection.vue';

export default {
  name: 'ErrorState',
  components: {
    PageSection
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    },
    retry: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
