const QUERY_COACHES = `
    *[_type == "coach"] | order(order asc) {
        name,
        slug,
        email,
        instagram,
        bio,
        "tags": tags.tags[]->title,
        "imageUrl": image.asset->url
    }
`;

export default QUERY_COACHES;
