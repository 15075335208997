<template>
  <img
    v-bind="$attrs"
    alt="UNIT 3 logo"
    :src="logo"
  >
</template>

<script>
const UNIT_3_LOGO = require('./assets/unit_3.svg');

export default {
  name: 'UNIT3',
  data: () => ({
    logo: UNIT_3_LOGO
  })
};
</script>
