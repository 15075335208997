import status from '../status';

const initialState = () => ({
  classes: [],
  isLoading: false,
  error: {
    description: null,
    isError: false,
    code: null,
    query: null
  },
  status,
  lastFetchTime: null
});

export default initialState;
