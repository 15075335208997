import landingRoutes from './landing.routes';
import proRoutes from './pros.routes';
import kidsRoutes from './kids.routes';
import beginnerRoutes from './beginner.routes';
import returningRoutes from './returning.routes';
import blogRoutes from './blog.routes';
import timetableRoutes from './timetable.routes';
import coachRoutes from './coaches.routes';
import glofoxRoutes from './glofox.routes';
import privacyPolicyRoutes from './privacyPolicy.routes';

export default [
  ...landingRoutes,
  ...proRoutes,
  ...kidsRoutes,
  ...beginnerRoutes,
  ...blogRoutes,
  ...timetableRoutes,
  ...coachRoutes,
  ...glofoxRoutes,
  ...privacyPolicyRoutes,
  ...returningRoutes
];
