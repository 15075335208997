<template>
  <PageSection
    :title="'Welcome to UNIT 3: Health and Fitness'"
    with-logo
  >
    <SectionText>
      Our goal is to create a <span class="text-primary font-normal">strong healthy community</span> of strong, healthy people.
    </SectionText>
    <SectionText>
      UNIT 3: Health and Fitness was built by three friends who share the same values and philosophies on well-being and who believe that improving your health can lead to noticeable improvements in all the other areas of your life.
    </SectionText>
    <SectionText>
      Our passion for coaching people to live well is what drives us.
    </SectionText>
    <SectionText>
      At UNIT 3: Health and Fitness, we provide <router-link to="/coaches">
        <span
          class="text-primary font-normal underline"
          role="link"
        >quality coaching and advice</span>
      </router-link> to help you achieve your goals.
    </SectionText>
  </PageSection>
</template>

<script>
import {PageSection} from '../shared/index';
import { SectionText } from '@/pages/shared/index';


export default {
  name: 'Welcome',
  components: {
    PageSection,
    SectionText
  }
};
</script>
