<template>
  <Modal
    v-show="isOpen"
    class="bg-secondary-DEFAULT"
  >
    <div class="flex flex-col relative h-full w-full gap-y-4">
      <div class="w-full flex flex-row justify-end pt-4 px-8">
        <button @click="$emit('close')">
          <font-awesome-icon
            icon="fa-solid fa-times"
            size="lg"
            class="white-text"
          />
        </button>
      </div>
      <div class="w-full flex flex-row justify-end px-8">
        <Map />
      </div>
      <div class="flex flex-col items-center px-8 gap-y-2 mt-2 font-thin">
        <div class="flex flex-row justify-center px-8 ">
          <Logo :class="'h-8'" />
        </div>
        <div class="text-primary text-lg">
          UNIT 3 Health and Fitness
        </div>
        <div class="text-gray-500">
          Fishery Lane
        </div>
        <div class="text-gray-500">
          Naas
        </div>
        <div class="text-gray-500">
          Co. Kildare
        </div>
        <div class="text-gray-500">
          W91 KA4C
        </div>
        <a
          :href="location"
          target="blank"
          class="mt-12 text-primary border border-primary py-1 px-2 rounded-md uppercase text-xs hover:bg-primary hover:text-black"
        >
          <font-awesome-icon
            icon="fa-solid fa-location-pin"
            size="md"
            class="mr-2"
          /> Open in Maps
        </a>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/atoms/Modals/Modal.vue';
import Logo from '@/components/atoms/Brand/Logo.vue';
import Map from '@/components/atoms/Map/Map.vue';

export default {
  name: 'ContactModal',
  components: {
    Modal,
    Logo,
    Map
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    location: 'https://www.google.com/maps/place/Unit+3+Health+and+Fitness/@53.2271866,-6.6352326,17.13z/data=!4m5!3m4!1s0x4867785feaa26be5:0xbf72f54409d615e5!8m2!3d53.2291139!4d-6.6333423'
  })
};
</script>
