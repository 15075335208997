import Vue from 'vue';
import VueRouter from 'vue-router';
import modules from '@/pages/routes';

Vue.use(VueRouter);

const routes = [ ...modules ];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
  linkActiveClass: 'nav-active',
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});


export default router;
