<template>
  <PageSection class="bg-transparent">
    <section class="overflow-hidden">
      <div
        class="container mx-auto space-y-2 md:space-y-0 md:gap-2 md:grid"
        :class="`md:grid-cols-${gridCols}`"
      >
        <div
          v-for="(image, index) in images"
          :key="index"
          class="w-full rounded h-64"
        >
          <img
            :src="image.image.asset.url"
            alt="image"
            class="h-full w-full object-fit object-contain object-cover"
          >
        </div>
      </div>
    </section>
  </PageSection>
</template>

<script>
import PageSection from '../PageSection.vue';

export default {
  name: 'Gallery',
  components: {
    PageSection
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    gridCols() {
      if(this.images.length <= 4) return 2;
      else if(this.images.length >4 && this.images.length <= 7) return 3;
      return 4;
    }
  }
};
</script>
