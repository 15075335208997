<template>
  <div class="w-screen bg-black flex flex-col pt-6 ">
    <div class="flex flex-row justify-between w-screen mb-12">
      <Container class="flex lg:flex-row flex-col justify-between items-center w-screen border-t border-secondary-100  ">
        <div class="flex flex-col items-center lg:items-start  w-full  lg:w-2/3 pt-4">
          <div>
            <Logo :class="'h-12 mb-6'" />
          </div>
          <div class="text-sm text-secondary-200 flex flex-col w-2/3 text-center md:text-left font-light ">
            In UNIT 3, we pride ourselves on our professional standards of coaching. We have built a strong, inclusive and friendly community within our gym and fully welcome all levels of fitness and ability.
          </div>
          <div class="flex flex-row gap-x-4 mt-6">
            <a
              role="link"
              href="https://www.facebook.com/UNIT3HealthandFitness/"
              rel="noreferrer"
              target="_blank"
              aria-label="UNIT 3 on Facebook"
            >
              <font-awesome-icon
                icon="fa-brands fa-facebook"
                class="opacity-80 hover:text-primary cursor-pointer"
              />
            </a>
            <a
              role="link"
              href="https://www.instagram.com/unit3healthandfitness/"
              rel="noreferrer"
              target="_blank"
              aria-label="UNIT 3 on Instrgram"
            >
              <font-awesome-icon
                icon="fa-brands fa-instagram"
                class="opacity-80 hover:text-primary cursor-pointer"
              />
            </a>
            <a
              role="link"
              href="https://www.youtube.com/channel/UCYXo_XUVxExFz0jmgboemFw"
              rel="noreferrer"
              target="_blank"
              aria-label="UNIT 3 on Youtube"
            >
              <font-awesome-icon
                icon="fa-brands fa-youtube"
                class="opacity-80 hover:text-primary cursor-pointer"
              />
            </a>
          </div>
        </div>
        <div class="flex flex-row justify-between w-full   lg:w-1/3   pt-4 h-full ">
          <div class="flex flex-col items-start lg:items-end">
            <span class="text-white text-sm mb-2">UNIT 3 for</span>
            <router-link to="/athletes">
              <span class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary">Athletes</span>
            </router-link>
            <router-link to="/beginners">
              <span class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary">Beginners</span>
            </router-link>
            <router-link to="/kids">
              <span class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary">Kids & Teens</span>
            </router-link>
            <router-link to="/returning">
              <span class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary">Return to Training</span>
            </router-link>
          </div>
          <div class="flex flex-col items-end">
            <span class="text-white text-sm  mb-2">UNIT 3</span>
            <button
              class="text-white text-sm opacity-70 font-light hover:opacity-100 hover:text-primary"
              style="line-height: 24px"
              @click="
                () =>
                  toggleModal({
                    modalName: 'location',
                    show: true,
                  })
              "
            >
              Locate us
            </button>
            <router-link to="/coaches">
              <span class="text-white text-sm opacity-70 font-light  hover:opacity-100 hover:text-primary">Meet the coaches</span>
            </router-link>
            <router-link to="/timetable">
              <span class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary">Timetable</span>
            </router-link>
            <a href="https://wa.me/353874060634">
              <span
                class="text-white text-sm opacity-70 font-light   hover:opacity-100 hover:text-primary cursor-pointer"
              >087 4060634</span>
            </a>
            <button
              class="text-white text-sm opacity-70 font-light  hover:opacity-100 hover:text-primary"
              @click="
                () =>
                  toggleModal({
                    modalName: 'contact',
                    subject: 'General enquiry',
                    show: true,
                  })
              "
            >
              Contact us
            </button>
          </div>
        </div>
      </Container>
    </div>
    <div class="flex w-screen h-16 ">
      <Container class="flex lg:flex-row flex-col justify-between items-center w-screen bg-black pb-4 lg:pb-0">
        <div class="text-xs text-white">
          &copy;
          <span id="copyright">
            {{ new Date().getFullYear() }}
          </span>
          UNIT 3: Health & Fitness
        </div>
        <div class="text-xs text-white flex flex-row gap-x-4">
          <router-link
            to="/privacy-policy"
          >
            <button
              class="text-white uppercase border-transparent  opacity-70 font-light   hover:opacity-100 hover:text-primary"
            >
              Privacy policy
            </button>
          </router-link>
          <a
            href="https://unit3.sanity.studio/desk"
            target="_blank"
            rel="noreferrer"
          >
            <span
              class="text-white uppercase border-transparent  opacity-70 font-light   hover:opacity-100 hover:text-primary"
            >Admin</span>
          </a>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Container from '@/components/atoms/Frames/Container.vue';
import Logo from './Brand/Logo.vue';

export default {
  name: 'Footer',
  components: {
    Logo,
    Container
  },
  methods: {
    ...mapActions([ 'toggleModal' ])
  }
};
</script>
