import status from '../status';

const initialState = () => ({
  coaches: [],
  isLoading: false,
  error: {
    message: null,
    isError: false
  },
  status,
  lastFetchTime: null
});

export default initialState;
