<template>
  <div :class="[ small ? 'h-30-screen' : ' h-80-screen', 'w-screen relative']">
    <img
      :src="imageSource"
      alt="UNIT 3 gym floor"
      :class="[ small ? 'h-30-screen' : ' h-80-screen']"
      class=" bg-no-repeat bg-cover bg-center bg-secondary-DEFAULT absolute object-cover w-screen"
    >
    <div
      :class="['h-full w-full  flex flex-col items-center justify-center absolute', overlay]"
    >
      <PageTitle
        :content="'UNIT 3: HEALTH AND FITNESS'"
        text-left
      />
      <SectionTitle>
        <template v-slot:content>
          <div class="flex flex-col w-full items-center">
            <p
              class="text-white font-thin leading-normal py-6 text-opacity-60"
              :class="[
                'text-base w-2/3 ',
                'text-center ',
                'lg:text-3xl'
              ]"
            >
              We are <b class="text-primary font-thin">much more</b> than your typical gym
            </p>
          </div>
        </template>
      </SectionTitle>
      <button
        v-if="!small"
        id="navigateToWelcomeSection"
        class="absolute bottom-0 outline-none focus:outline-none flex flex-col w-24 items-center mb-4"
        type="button"
        @click="$emit('scroll-to')"
      >
        <div class="focus:outline-none font-normal text-secondary-200">
          Get started
        </div>

        <font-awesome-icon
          icon="fa-solid fa-angle-down"
          size="lg"
          class="text-secondary-200"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { PageTitle, SectionTitle } from '@/pages/shared/index';

export default {
  name: 'Banner',
  components: {
    PageTitle,
    SectionTitle
  },
  props: {
    banner: {
      type: String,
      default: 'We are much more than your typical gym'
    },
    small: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: require('./assets/banner.webp')
    },
    overlay: {
      type: String,
      default: 'bg-black bg-opacity-70'
    }
  },
  data: () => ({
    isLoading: true,
    imageSource: null
  }),
  created() {
    this.imageSource = this.source;
  }
};
</script>
