<template>
  <AsyncContainer
    :is-loading="getCoachesIsLoading"
    :is-error="getCoachesIsError"
    :error="getCoachesError"
    :retry="fetchCoaches"
    :title="'Our coaches'"
    class="bg-black"
  >
    <div class="flex flex-row">
      <div class="flex lg:flex-row w-full flex-col gap-x-6">
        <div
          class="flex flex-1 flex-col gap-4 items-center text-center w-full"
        >
          <SectionText>
            <span
              class="text-primary font-normal"
            >UNIT 3</span> opened in August 2017 with a few weights, some bands and three coaches who love to coach.
          </SectionText>
          <SectionText>
            Although we have invested in new equipment since then we have held to this belief and invested in our staff more.
          </SectionText>
          <SectionText>
            Regularly attending courses or seminars, taking on personal challenges and educating themselves in their spare time, our coaches share their knowledge with the members of UNIT 3 through classes, 1-1 training and our UNIT 3 seminars held throughout the year.
          </SectionText>
          <SectionText>
            All of our coaches are fully qualified and Garda Vetted.
          </SectionText>
          <router-link :to="`/coaches`">
            <button
              class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4 mt-6"
            >
              Meet our coaches
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </AsyncContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SectionText,  AsyncContainer } from '@/pages/shared/index';

export default {
  name: 'Coaches',
  components: {
    AsyncContainer,
    SectionText
  },
  props: {
    canMeetCoaches: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    content: [
      'UNIT 3 opened in 2017 with some weights, some bands and 3 coaches who love to coach. It was our belief back then and it still is to this day that great coaches make a great gym, not fancy equipment.',
      'Although we have invested in new equipment since then we have held to this belief and invested in our staff more. Regularly attending courses or seminars, taking on personal challenges and educating themselves in their spare time, our coaches share their knowledge with the members of UNIT 3 through classes, 1-1 training and our UNIT 3 seminars held throughout the year.',
      'All our coaches are qualified to coach both the strength and conditioning classes and our boxing classes.'
    ]
  }),
  computed: {
    ...mapGetters('coaches', [
      'getCoaches',
      'getCoachesIsError',
      'getCoachesIsLoading',
      'getCoachesError'
    ]),
    randomAvatars() {
      const filteredCoaches = this.getCoaches.filter(c => c.imageUrl).filter((coach) => coach.bio);

      const randomCoaches = filteredCoaches
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)
        .map((coach) => {
          return {
            url: coach.imageUrl, name: coach.name
          };
        });
      return randomCoaches;
    }
  },
  async created() {
    await this.fetchCoaches();
  },
  methods: {
    ...mapActions('coaches', [ 'fetchCoaches' ])
  }
};
</script>
