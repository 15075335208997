<template>
  <AsyncContainer
    v-observe-visibility="visibilityChanged"
    :is-loading="getClassesIsLoading"
    :is-error="getClassesIsError"
    :error="getClassesError"
    :retry="fetchClasses"
    :title="'Classes'"
  >
    <div class="grid grid-cols-6 gap-6 my-12">
      <div
        v-for="(item, index) in classes.length ? classes : getClasses"
        :key="index"
        class="lg:col-span-3 xl:col-span-2 md:col-span-3 col-span-6 flex flex-grow"
      >
        <Card
          :id="item.title.replace(' ', '_')"
          :title="item.title"
          :subtitle="null"
          :body="item.description"
          :tags="item.tagColors"
          :can-book="getFeatures.book_classes"
          :learn-more="false"
          :background-image="item.image"
          bordered
        >
          <template
            v-if="item.title === 'Personal Training'"
            v-slot:footer
          >
            <button
              class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
              @click="
                (title) =>
                  toggleModal({
                    modalName: 'contact',
                    subject: 'Peronsal training',
                    show: true,
                  })
              "
            >
              Contact us
            </button>
          </template>
          <template
            v-else
            v-slot:footer
          >
            <button
              class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"

              @click="$emit('scroll-to')"
            >
              Book class
            </button>
          </template>
        </Card>
      </div>
    </div>
  </AsyncContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AsyncContainer, Card } from '@/pages/shared/index';

export default {
  name: 'Classes',
  components: {
    AsyncContainer,
    Card
  },
  props: {
    classes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('gymClasses', [
      'getClasses',
      'getClassesIsError',
      'getClassesIsLoading',
      'getClassesError'
    ]),
    ...mapGetters('features', [ 'getFeatures' ])
  },
  methods: {
    ...mapActions('gymClasses', [ 'fetchClasses' ]),
    ...mapActions([ 'toggleModal' ]),
    async fetchData() {
      if (!this.classes.length) await this.fetchClasses();
    },
    visibilityChanged(isVisible) {
      if (isVisible) this.fetchData();
    }
  }
};
</script>
