<template>
  <div class="flex md:flex-row flex-col w-full items-center justify-center gap-4">
    <Logo
      v-if="withLogo"
      class="h-12"
    />
    <h1
      class="text-white text-opacity-80 font-base uppercase leading-normal text-center tracking-widest"
      :class="[
        'text-xl ',
        'sm:text-2xl',
        'md:text-3xl',
        'lg:text-4xl',
        'xl:text-5xl',
      ]"
    >
      {{ content }}
    </h1>
  </div>
</template>

<script>
import Logo from './Brand/Logo.vue';

export default {
  name: 'PageTitle',
  components: {
    Logo
  },
  props: {
    content: {
      type: String,
      default: ''
    },

    withLogo: {
      type: Boolean,
      default: false
    }
  }
};
</script>
