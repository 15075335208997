<template>
  <div
    class="w-screen min-h-screen h-full bg-black text-white"
    :class="isOpen && 'overflow-y-hidden max-h-screen'"
  >
    <StaticNavbar
      :features="features"
      @clicked="isOpen = !isOpen"
    />
    <NavigationDrawer
      :is-open="isOpen"
      :features="features"
      @clicked="isOpen = !isOpen"
      @selected="isOpen = false"
    />
    <slot :features="features" />
    <Footer />
  </div>
</template>

<script>
import { StaticNavbar, NavigationDrawer, Footer } from '@/pages/shared/index';

export default {
  name: 'BlogPost',
  components: {
    StaticNavbar,
    NavigationDrawer,
    Footer
  },
  props: {
    features: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isOpen: false
  }),

  computed: {
    title() {
      if(this.$route?.params?.slug){
        const slug = this.$route.params.slug.replace('-',' ');
        return slug[0].toUpperCase() + slug.substring(1);
      }
      return this.$route.meta.title;
    }
  }
};
</script>
