<template>
  <div class="w-full h-auto p-12 flex flex-col gap-y-4 items-center justify-center">
    <div class="text-white">
      An error has occured
    </div>
    <div class="text-white">
      <b class="font-black text-secondary-200">Code: </b>{{ error.code }}
    </div>
    <div class="text-white">
      <b class="font-black text-secondary-200">Description: </b>{{ error.description }}
    </div>
    <div class="text-white">
      <b class="font-black text-secondary-200">Occured in: </b>{{ error.query }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingError',
  props: {
    message: {
      type: String,
      default: null
    },
    code: {
      type: Number,
      default: 0
    },
    error: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
