/* eslint-disable vue/no-unused-components */
<template>
  <AsyncContainer
    v-observe-visibility="visibilityChanged"
    :is-loading="getMainCampaignIsLoading && !getMainCampaign"
    :is-error="getMainCampaignIsError"
    :error="getMainCampaignError"
    :retry="fetchMainCampaign"
    class="bg-secondary-DEFAULT"
    :title="getMainCampaign && getMainCampaign.title"
  >
    <div
      :class="[
        'md:grid md:grid-cols-6 lg:text-left gap-6 flex flex-col text-center',
      ]"
    >
      <div class="md:col-span-4 my-auto">
        <BlogBlocks :content="body" />

        <ULink
          class="w-auto mt-4"
          @click="$emit('click-campaign', getMainCampaign.emailSubject)"
        >
          <div
            class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
            :aria-label="`Contact ${contactEmail} to schedule an appointment`"
          >
            Contact us to schedule an appointment
          </div>
        </ULink>
      </div>
      <div :class="['md:col-span-2']">
        <div class="flex flex-col items-center justify-center">
          <img
            :src="image"
            :class="['object-cover relative h-96 ']"
          >
        </div>
      </div>
    </div>
  </AsyncContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AsyncContainer} from '../shared/index';
import Link from '@/components/atoms/Navigation/Link.vue';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'Campaign',
  components: {
    AsyncContainer,
    ULink: Link,
    BlogBlocks
  },
  computed: {
    ...mapGetters('mainCampaign', [
      'getMainCampaign',
      'getMainCampaignIsError',
      'getMainCampaignError',
      'getMainCampaignIsLoading'
    ]),
    body() {
      return this.getMainCampaign?.body;
    },
    contactEmail() {
      return this.getMainCampaign?.contactEmail || '';
    },
    image() {
      return this.getMainCampaign?.image?.asset?.url || '';
    }
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('mainCampaign', [ 'fetchMainCampaign' ]),
    ...mapActions('content', [ 'fetchContent' ]),
    visibilityChanged(isVisible) {
      if (isVisible) this.fetchData();
    },
    async fetchData() {
      if (!this.getMainCampaign) await this.fetchMainCampaign();
    }
  }
};
</script>
