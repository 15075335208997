import sanity from '@/infrastructure/sanity/sanity';
import QUERY_IMAGES from './queries';
import { SET_IMAGES, SET_IMAGES_LOADING, SET_IMAGES_ERROR } from './types';

export default {
  fetchImages: async ({ commit }, page) => {
    commit(SET_IMAGES_LOADING, true);
    commit(SET_IMAGES_ERROR, { description: null, isError: false, code: null });
    sanity.fetch(QUERY_IMAGES(page)).then(
      (images) => {
        commit(SET_IMAGES, images);
      },
      (error) => {
        commit(SET_IMAGES_ERROR, {
          description: error.details.description,
          code: error.response.statusCode,
          isError: true,
          query: 'fetchImages'
        });
      }
    );
    commit(SET_IMAGES_LOADING, false);
  }
};
