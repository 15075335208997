const KidsPage = () => import(/* webpackPrefetch: true */ '@/pages/KidsPage.vue');

export default [
  {
    path: '/kids',
    name: 'KidsPage',
    component: KidsPage,
    meta: {
      layout: 'Page',
      title: 'Set your kids up for a lifetime of good habits...',
      linkContent: 'about kids training at UNIT 3',
      seo: {
        title: 'UNIT 3: Health & Fitness | Kids',
        description: 'Set your kids up for a lifetime of good habits at UNIT 3'
      }
    }
  }
];
