<template>
  <PageSection
    :title="'Train with UNIT 3'"
    class="bg-secondary-DEFAULT"
  >
    <div class="w-full h-auto bg-transparent grid grid-cols-2 gap-12 mt-6">
      <div
        v-for="(item, index) in cardData"
        :key="index"
        class="md:col-span-1 col-span-4 flex flex-grow"
      >
        <Card
          :title="item.title"
          :subtitle="item.subtitle"
          :body="item.content"
          :background-image="require(`@/assets/classes/${item.media}`)"
          bordered
        >
          <template v-slot:footer>
            <router-link :to="item.to">
              <button class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4">
                {{ item.subtitle }}
              </button>
            </router-link>
          </template>
        </Card>
      </div>
    </div>
  </PageSection>
</template>

<script>
import {PageSection, Card} from '../shared/index';

export default {
  name: 'TargetSection',
  components: {
    PageSection,
    Card
  },
  props: {
    cardData: {
      type: Array,
      default: () => []
    }
  }
};
</script>
