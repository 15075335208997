const BeginnersPage = () => import(/* webpackPrefetch: true */ '@/pages/BeginnersPage.vue');

export default [
  {
    path: '/beginners',
    name: 'BeginnersPage',
    component: BeginnersPage,
    meta: {
      layout: 'Page',
      title: 'Everyone starts somewhere. Start with UNIT 3.',
      pageTitle: 'UNIT 3: Get started',
      linkContent: 'about starting with UNIT 3',
      seo: {
        title: 'UNIT 3: Health & Fitness | Get started at UNIT 3',
        description: 'Everyone starts somewhere. Start with UNIT 3.'
      }
    }
  }
];
