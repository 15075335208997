<template>
  <div
    ref="navbar"
    :class="[
      currentScroll > scrollTrigger ? 'navbar--threshold h-12' : 'navbar h-auto py-2',
      'fixed top-0 w-full flex justify-between items-center z-40'
    ]"
    :style="navbarColor"
    v-bind="$attrs"
  >
    <Container class="justify-between">
      <Logo
        :class="[
          opacity.toString() === '1.0' ? 'h-8' : opacityMap[opacity],
          !opacity && 'h-12'
        ]"
      />
      <button
        :name="'toggle-menu'"
        aria-label="toggle-menu"
        class="outline-none focus:outline-none"
        @click="$emit('clicked')"
      >
        <font-awesome-icon
          icon="fa-solid fa-bars"
          size="lg"
          class="white-text"
        />
      </button>
    </Container>
  </div>
</template>

<script>

import Container from '@/components/atoms/Frames/Container.vue';
import Logo from '@/components/atoms/Brand/Logo.vue';

const opacityMap = {
  0.0: 'h-12',
  0.1: 'h-12',
  0.2: 'h-12',
  0.3: 'h-11',
  0.4: 'h-11',
  0.5: 'h-10',
  0.6: 'h-10',
  0.7: 'h-9',
  0.8: 'h-9',
  0.9: 'h-8',
  1.0: 'h-8'
};

export default {
  name: 'DynamicNavbar',
  components: {
    Container,
    Logo
  },
  data: () => ({
    currentScroll: null,
    navbarHeight: null,
    logoHeight: 'h-12',
    maxScroll: 0,
    opacity: 0.0,
    opacityMap
  }),
  computed: {
    scrollTrigger() {
      return ((window.innerHeight / 100) * 60) - this.navbarHeight;
    },
    navbarColor() {
      return { backgroundColor: `rgba(0, 0, 0, ${this.opacity})` };
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScrollPosition);
    this.navbarHeight = this.$refs.navbar.clientHeight;
    this.maxScroll = ((window.innerHeight / 100) * 60) - this.navbarHeight;
    this.opacity = Math.min(1, this.currentScroll / this.maxScroll).toFixed(1);
  },
  methods: {
    updateScrollPosition() {
      this.currentScroll = window.pageYOffset || document.documentElement.scrollTop
        || document.body.scrollTop || 0;
      this.opacity = Math.min(1, this.currentScroll / this.maxScroll).toFixed(1);
    }
  }
};
</script>

<style lang="scss">
.navbar {
  background-color: transparent;
  transition: all .2s ease-in-out;
  &--threshold {
    transition: all .2s ease-in-out;
  }
}

</style>
