<template>
  <form
    name="Basic contact form"
    method="post"
    class="flex flex-col gap-y-2"
    @submit.prevent="handleSubmit"
  >
    <input
      name="form-name"
      type="hidden"
      value="Basic contact form"
    >
    <input
      v-model="form.name"
      :name="'name'"
      class="field"
      :type="'text'"
      :placeholder="'Your name'"
      required
    >
    <input
      v-model="form.email"
      :name="'email'"
      class="field"
      :type="'text'"
      :placeholder="'Your email'"
      required
    >
    <input
      v-model="form.number"
      :name="'number'"
      class="field"
      :type="'tel'"
      :placeholder="'Your contact number'"
      required
    >
    <input
      v-model="formSubject"
      :name="'subject'"
      class="field text-gray-500"
      :type="'text'"
      :placeholder="'Subject'"
      disabled
    >
    <textarea
      v-model="form.message"
      :name="'message'"
      class="field"
      :type="'text'"
      :placeholder="'Your message'"
      required
    />
    <label class="flex justify-start items-start mt-2">
      <div
        class=""
        :class="[
          'checkbox',
          form.agreed ? 'border-primary' : 'border-secondary-DEFAULT'
        ]"
      >
        <input
          v-model="form.agreed"
          type="checkbox"
          class="opacity-0 absolute"
          required
        >
        <svg
          class="fill-current hidden w-3 h-3 text-primary pointer-events-none"
          viewBox="0 0 20 20"
        >
          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
        </svg>
      </div>
      <div class="select-none text-white text-opacity-50 font-thin text-xs my-auto">By submitting this form I agree to be contacted by UNIT 3 in relation to my enquiry</div>
    </label>
    <button
      :name="'submit-basic-contact-form'"
      type="submit"
      class=" flex-none w-40 mt-4 rounded-md px-4 py-3 text-xs font-bold  border-2 outline-none"
      :class="isDisabled ? 'cursor-not-allowed bg-secondary-200 border-secondary-200 text-black' : 'bg-primary text-black u-link'"
      :disabled="isDisabled"
    >
      Contact UNIT 3
    </button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Basic',
  data: () => ({
    form: {
      name: null,
      email: null,
      number: null,
      message: null,
      agreed: false
    }
  }),
  computed: {
    ...mapGetters([ 'formSubject' ]),
    isDisabled() {
      return Object.keys(this.form).some((k) => !this.form[k]);
    }
  },
  methods: {
    ...mapActions([ 'submitBasicContactForm' ]),
    resetForm() {
      this.form.name = null;
      this.form.email = null;
      this.form.number = null;
      this.form.message = null;
      this.form.agreed = false;
      this.form.subject = this.formSubject;
      this.$emit('close');
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
    },
    async handleSubmit() {
      this.form.subject = this.formSubject;
      await this.submitBasicContactForm(
        {
          formName: 'Basic contact form',
          form: this.form,
          message: 'Thank your for your enquiry. A member of UNIT 3 will be in touch.'
        }
      );
      this.resetForm();
    }
  }
};
</script>
<style lang="scss">
.field {
  @apply w-full rounded-sm p-3 bg-secondary-DEFAULT border-b border-secondary-200  text-white outline-none resize-none font-hairline text-sm;
}
textarea::placeholder,
input::placeholder {
  @apply text-white font-hairline text-sm;
}

textarea::-moz-focus-inner,
input::-moz-focus-inner {
  @apply border-teal-500;
}

input:checked + svg {
  display: block;
}
.checkbox {
  @apply bg-transparent border-2 rounded w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2;
}
</style>
