export default {
  getClasses: (state) => state.classes,
  getClassesIsError: (state) => state.status.isError,
  getClassesError: (state) => state.status.error,
  getClassesIsLoading: (state) => state.status.isLoading,
  getAthleteClasses: (state) =>
    state?.classes?.filter((group) =>
      [ 'athletes', 'personal training' ].some((i) =>
        group?.tags?.map((t) => t.toLowerCase()).includes(i)
      )
    ),
  getBeginnerClasses: (state) =>
    state?.classes?.filter((group) =>
      [ 'beginners', 'adults', 'personal training' ].some((i) =>
        group?.tags?.map((t) => t.toLowerCase()).includes(i)
      )
    ),

  getKidsClasses: (state) =>
    state?.classes?.filter((group) =>
      [ 'kids', 'teens' ].some((i) => group?.tags?.map((t) => t.toLowerCase()).includes(i))
    )
};
