<template>
  <div
    class="w-screen min-h-screen h-auto bg-black text-white"
    :class="isOpen && 'overflow-y-hidden max-h-screen'"
  >
    <DynamicNavbar
      :features="features"
      @clicked="isOpen = !isOpen"
    />
    <NavigationDrawer
      :is-open="isOpen"
      :features="features"
      @clicked="isOpen = !isOpen"
      @selected="isOpen = false"
    />
    <slot :features="features" />
    <Footer />
  </div>
</template>

<script>
import { DynamicNavbar, NavigationDrawer, Footer } from '@/pages/shared/index';
export default {
  name: 'Default',
  components: {
    DynamicNavbar,
    NavigationDrawer,
    Footer
  },
  props: {
    features: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isOpen: false
  })
};
</script>
