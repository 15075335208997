<template>
  <div :class="['h-30-screen  w-screen relative']">
    <img
      :src="imageSource"
      alt="UNIT 3 gym floor"
      :class="'h-30-screen' "
      class=" bg-no-repeat bg-cover bg-center bg-secondary-DEFAULT absolute object-cover w-screen"
    >
    <div
      :class="['h-full w-full  flex flex-col items-center justify-center absolute bg-gradient-to-t via-transparent  from-black', overlay]"
    >
      <SectionTitle
        :content="banner"
      />
    </div>
  </div>
</template>

<script>
import { SectionTitle } from '@/pages/shared/index';

export default {
  name: 'PageBanner',
  components: {
    SectionTitle
  },
  props: {
    banner: {
      type: String,
      default: 'We are much more than your typical gym'
    },
    small: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: require('./assets/banner.webp')
    },
    overlay: {
      type: String,
      default: 'bg-black bg-opacity-0'
    }
  },
  data: () => ({
    isLoading: true,
    imageSource: null
  }),
  created() {
    this.$emit('has-mounted');
    this.imageSource = this.source;
  }
};
</script>
