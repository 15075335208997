export default [
  {
    to: '/',
    name: 'Home',
    active: 'LandingPage',
    flag: true
  },
  {
    to: '/timetable',
    name: 'Timetable',
    active: 'TimetablePage',
    flag: 'timetable_route'
  },
  {
    to: '/',
    name: 'Classes',
    active: 'ClassesPage',
    flag: false
  },
  {
    to: '/glofox/class-booking',
    name: 'Book a class',
    active: 'GlofoxPage',
    flag: false
  },
  {
    to: '/coaches',
    name: 'Coaches',
    active: 'CoachesPage',
    flag: 'coaches_route'
  },
  {
    to: '/blog',
    name: 'Blog',
    active: 'BlogIndex',
    flag: 'blog_route'
  }
];
