<template>
  <div
    v-bind="$attrs"
    :class="[' bg-secondary-DEFAULT animate-pulse rounded-lg', height, width]"
  />
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    height: {
      type: String,
      default: 'h-24'
    },
    width: {
      type: String,
      default: 'w-full'
    }
  }
};
</script>
