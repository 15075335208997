<template>
  <div
    v-bind="$attrs"
    class="w-full flex flex-col relative"
  >
    <PageSection :title="title">
      <slot name="content" />
      <LoadingState
        v-if="isLoading"
      />
      <ErrorState
        v-else-if="isError"
        :error="error"
        :retry="retry"
      />
      <div v-else>
        <slot />
      </div>
    </PageSection>
  </div>
</template>

<script>

import PageSection from './PageSection.vue';
import LoadingState from './LoadingState.vue';
import ErrorState from './ErrorState.vue';

export default {
  name: 'AsyncContainer',
  components: {
    PageSection,
    ErrorState,
    LoadingState
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object,
      default: () => {}
    },
    retry: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
