<template>
  <div class="flex flex-col w-full sm:items-center sm:justify-center">
    <p
      class="font-thin opacity-70  leading-tight my-2"
      :class="[
        'xl:text-base xl:w-3/4 xl:text-center',
        'text-sm text-center'
      ]"
    >
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'SectionText',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
};
</script>
