<template>
  <transition name="slide">
    <div class="drawer">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Drawer'
};
</script>

<style lang="scss">
.drawer {
  @apply bg-black  h-full w-full fixed text-white items-center justify-center flex flex-col z-40;
}
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(-100%);
    transition: all 150ms ease-in 0s
}
</style>
