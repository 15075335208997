<template>
  <transition
    name="slide"
  >
    <div
      class="modal"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal'
};
</script>

<style lang="scss" scoped>
.modal {
  @apply h-screen w-full fixed text-white items-center justify-center flex flex-col z-70 right-0 top-0;
}
@media (min-width: 640px) {
  .modal {
    @apply w-11/12;
  }
}
@media (min-width: 768px) {
  .modal {
    @apply w-9/12;
  }
}
@media (min-width:1024px) {
  .modal {
    @apply w-1/2;
  }
}
.overlay {
  @apply bg-black bg-opacity-70 w-screen h-screen fixed z-60 overflow-hidden;
}
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transition: all 150ms ease-in 0s;
  transform: translateX(50%);
}
</style>
