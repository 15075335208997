<template>
  <div class="slider">
    <div class="line bg-primary" />
    <div class="subline inc bg-primary" />
    <div class="subline dec bg-primary" />
  </div>
</template>

<script>
export default {
  name: 'Loading'
};
</script>

<style scoped lang="scss">
.slider {
  position: absolute;
  width: 100%;
  height: 2px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  width: 150%;
  height: 2px;
}

.subline {
  position: absolute;
  height: 2px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
