const PrivacyPolicyPage = () => import(/* webpackPrefetch: true */ '@/pages/PrivacyPolicyPage.vue');

export default [
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
    meta: {
      layout: 'Page',
      title: 'Privacy Policy',
      pageTitle: 'UNIT 3: Privacy Policy',
      seo: {
        title: 'UNIT 3: Health & Fitness | Privacy Policy',
        description: 'Privacy Policy'
      }
    }
  }
];
