const QUERY_GYM_CLASSES = `
    *[_type == "gymClass" && enabled == true]  | order(order asc) {
        title,
        slug,
        image,
        description,
        "tags": tags.tags[]->title,
        "tagColors": tags.tags[]->,
        "image": image.asset->url
    }
`;

export default QUERY_GYM_CLASSES;
