// TODO Move to sanity

export default {
  cards: {
    training: [
      {
        title: 'Athletes',
        subtitle: 'Improve at UNIT 3',
        content: 'Improve in your sport by joining our classes and working with our professional coaches.',
        media: 'u_strong.png',
        to: '/athletes'
      },
      {
        title: 'Kids & Teen Boxing',
        subtitle: 'Grow at UNIT 3',
        content: 'Train, learn and grow with these classes designed for kids and teenagers who want to learn how to box correctly. Taught by our professional boxing coaches, these classes are suitable for boys and girls of all levels.',
        media: 'u_box.png',
        to: '/kids'
      },
      {
        title: 'New to Training',
        subtitle: 'Learn at UNIT 3',
        content: 'At UNIT 3 we pride ourselves in making that initial first step into fitness as welcoming and as friendly as possible.',
        media: 'return_training.jpeg',
        to: '/beginners'
      },
      {
        title: 'Return to Training',
        subtitle: 'Achieve at UNIT 3',
        content: 'Whether you are returning from an injury, are a more advanced gym-goer or are just looking for something new, let us help you reach your goals through our structured programming, encouraging atmosphere and welcoming community.',
        media: 'personal_training.png',
        to: '/returning'
      }
    ]
  }
};
