<template>
  <div class=" h-96 relative w-full">
    <GmapMap
      :center="center"
      :zoom="zoom"
      class="responsive-iframe"
      :options="mapOptions"
    >
      <gmap-marker
        :position="center"
        :clickable="false"
        :draggable="false"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'Map',
  data: () => ({
    center: { lat: 53.229062, lng: -6.633003 },
    zoom: 14,
    mapOptions: {
      streetViewControl: false,
      rotateControl: false,
      controlSize: 25,
      styles: [
        {
          elementType: 'geometry',
          stylers: [ {
            color: '#212121'
          } ]
        },
        {
          elementType: 'labels.icon',
          stylers: [ {
            visibility: 'off'
          } ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#757575'
          } ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [ {
            color: '#212121'
          } ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [ {
            color: '#757575'
          } ]
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#9e9e9e'
          } ]
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [ {
            visibility: 'off'
          } ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#bdbdbd'
          } ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#757575'
          } ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [ {
            color: '#181818'
          } ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#616161'
          } ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.stroke',
          stylers: [ {
            color: '#1b1b1b'
          } ]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [ {
            color: '#2c2c2c'
          } ]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#8a8a8a'
          } ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [ {
            color: '#373737'
          } ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [ {
            color: '#3c3c3c'
          } ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [ {
            color: '#4e4e4e'
          } ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#616161'
          } ]
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#757575'
          } ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [ {
            color: '#000000'
          } ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [ {
            color: '#3d3d3d'
          } ]
        }
      ]
    },
    coordinates: {
      0: {
        full_name: 'UNIT 3 Health and Fitness',
        lat: 53.229062,
        lng: -6.633003
      }
    }
  }),
  computed: {
  },
  methods: {
    getPosition(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng)
      };
    }
  }
};
</script>
<style scoped lang="scss">
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

</style>
