import landing from './content/landing/landing';

const initialState = () => ({
  pages: {
    landing
  },
  alert: {
    show: false,
    message: null,
    alertState: null
  },
  modals: {
    open: false,
    subject: 'General enquiry from unit3.ie',
    contact: {
      show: false
    },
    location: {
      show: false
    }
  }
});

export default initialState;
