<template>
  <div class="iframe-container w-full bg-black">
    <div class="overflow-auto h-full w-full">
      <iframe
        v-show="isLoaded"
        :id="id"
        :ref="id"
        v-resize="{
          log: false,
          checkOrigin: false,
          tolerance: 10,
          sizeHeight: true,
          heightCalculationMethod: 'lowestElement',
          minHeight: 600,
          bodyPadding: 0,
          scrolling: 'auto',
        }"
        :src="source"
        gesture="media"
        allow="encrypted-media"
        allowfullscreen
        @load="handleLoad"
      />
      <div
        v-show="isLoaded"
        class="w-full text-gray-600 mb-12 text-right text-xs"
      >
        <a href="https://www.glofox.com">powered by <b>Glofox</b></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlofoxPortal',
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isLoaded: false
  }),
  methods: {
    handleLoad() {
      this.isLoaded = true;
      this.$emit('loaded');
    }
  }
};
</script>

<style lang="scss">
.iframe-container iframe {
  width: 100%;
}
</style>
