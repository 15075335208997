<template>
  <a
    v-if="href"
    :href="href"
    v-bind="$attrs"
    target="_blank"
    rel="noreferrer noopener"
  >
    <slot />
  </a>

  <router-link
    v-else-if="to"
    :to="to"
    v-bind="$attrs"
    exact
  >
    <slot />
  </router-link>

  <button
    v-else
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Link',
  props: {
    href: {
      type: String,
      default: undefined
    },
    to: {
      type: String,
      default: undefined
    }
  }
};
</script>
